import React, { ReactElement } from 'react'
import { Box, Theme } from '@mui/material'

import Button from '@components/core/input/Button'

export type PageFooterProps = {
  linkTo?: MCDC.Props.IButtonTo
  sx?: MCDC.Props.IDefault['sx']
}

export default function PageFooter({
  linkTo,
  sx,
}: PageFooterProps): ReactElement | null {
  return (
    <Box sx={sx}>
      {linkTo && (
        <Box
          sx={(theme: Theme) => ({
            backgroundColor: 'common.white',
            boxShadow: '0px -4px 15px rgba(0, 0, 0, 0.15)',
            [theme.breakpoints.up('md')]: {
              display: 'flex',
              justifyContent: 'center',
              py: 3,
            },
          })}
        >
          <Button
            to={linkTo.url}
            isExternal={linkTo.isExternal}
            isLoading={linkTo.isLoading}
            onClick={linkTo.onClick}
            color="primary"
            sx={(theme: Theme) => ({
              [theme.breakpoints.down('md')]: {
                width: '100%',
                borderRadius: '0 !important',
              },
              [theme.breakpoints.up('md')]: {
                minWidth: '434px',
              },
            })}
          >
            {linkTo.label}
          </Button>
        </Box>
      )}
    </Box>
  )
}
