import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'

import { normalizeRichText, richTextOptions } from '@utils/richtext'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import StickyActions from '@components/site/container/StickyActions'
import PageFooter from '@components/modules/PageFooter'

export default function PageSeo({
  data,
  location,
}: MCDC.Contentful.IPageSeo): ReactElement {
  const { content } = data.contentfulPageSeo
  const documentContent = normalizeRichText(content, { location })

  return (
    <>
      <StickyActions>
        <PageFooter />
      </StickyActions>
      {documentContent &&
        renderRichText(
          {
            raw: JSON.stringify(documentContent.raw),
            references: documentContent.references,
          },

          richTextOptions
        )}
    </>
  )
}

export const pageQuery = graphql`
  query PageSEOByPath($id: String!, $locale: String!) {
    contentfulPageSeo(id: { eq: $id }, node_locale: { eq: $locale }) {
      content {
        raw
        references {
          ...RichTextFragment
        }
      }
    }
  }
`
